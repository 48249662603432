<div style="text-align: -webkit-center;">
    <div [ngClass]="{
        'buscador-container': true, 
        'buscador-principal': !sede, 
        'buscador-sedes': sede
    }">
        <h1 class="titulo-buscador quicksand-regular" *ngIf="!sede">ELEGÍ TU CARRERA</h1>
        <h5 class="subtitulo-buscador quicksand-medium" *ngIf="!sede">Encontrá toda la información sobre la carrera que
            te interesa</h5>
        <h4 class="titulo-buscador titulo-buscador-sede quicksand-regular" *ngIf="sede">ENCONTRÁ TU CARRERA EN NUESTRA
            SEDE</h4>

        <form [formGroup]="buscador" class="form-group" id="busqueda"
            style='margin-bottom:0px;justify-content: space-between;' #busqueda>

            <div class="container-responsive">
                <div class="input-container">
                    <input #filtroInput autofocus type="text" formControlName='filtro' class="form-control-c "
                        placeholder="Palabras clave"
                        (keyup)="(filtroInput.value.length >= 5 || filtroInput.value.length==0)? filtrar($event) : null"
                        style="padding-left: 10px !important">
                    <img class="buscar" src="../../../../../assets/img/buscar-azul.png" alt="">
                </div>


                <div class="input-container">
                    <!-- <select id="nivel" formControlName="nivel" class="form-control" (change)="filtrar($event)"
                        style="border-radius: 0px !important;">
                        <option value=''>Elegí el nivel</option>
                        <option class="option" *ngFor='let item of niveles' [value]='item' class="item-result">{{item}}
                        </option>
                    </select> -->
                    <app-custom-select  [colorArrow]="'azul'"  placeholderAll="Todas los niveles" [options]="niveles"
                        placeholder="Elegí el nivel" (selectionChange)="updateFilter('nivel', $event)" [ingreso]="true"
                        [id]="'select-nivel'">
                    </app-custom-select>
                </div>
                <div class="input-container" *ngIf="!sede">

                    <!-- <select id="sedes" formControlName="sede" class="form-control " (change)="filtrar($event)"
                        style="border-radius: 0px !important;">
                        <option value=''>Elegí tu sede</option>
                        <option class="option" *ngFor='let item of regiones' [value]='item.id'>{{item.title}}</option>
                    </select> -->
                    <app-custom-select  [colorArrow]="'azul'"  placeholderAll="Todas las regiones" [options]="regiones"
                        placeholder="Elegí tu sede" (selectionChange)="updateFilter('sede', $event)" [ingreso]="true"
                        [id]="'select-sede'">
                    </app-custom-select>
                </div>
                <div class="input-container" style="margin-right: 0% !important;">
                    <!--  <select id="area" formControlName="area" class="form-control" (change)="filtrar($event)"
                        style="border-radius: 0px !important;">
                        <option value=''>Elegí tu facultad</option>
                        <option class="option" *ngFor='let item of areas' [value]='item.id'>{{item.title}}</option>
                    </select> -->
                    <app-custom-select  [colorArrow]="'azul'"  [options]="areas" placeholder="Elegí tu facultad"
                        placeholderAll="Todas las facultades" (selectionChange)="updateFilter('area', $event)"
                        [ingreso]="true" [id]="'select-facultad'">
                    </app-custom-select>
                </div>
                <!--  <select id="modalidad" formControlName="modalidad" class="form-control" (change)="filtrar($event)" >
                    <option value=''>Modalidad</option>
                    <option *ngFor='let item of modalidades' [value]='item'>{{item}}</option>
                </select> -->

                <!-- <div class='text-center container-btn'>
                   <a class=""><img class="buscar" src="../../../../../assets/img/botonBuscar.png" alt=""></a>
       
               </div> -->
            </div>

        </form>
    </div>
    <div *ngIf="sede" routerLink="/es/ingreso" class="breadcump">
        <span class="nunito-medium"> Carreras | <span class="nunito-bold"> {{sede}}</span></span>
    </div>
</div>

<div #swipeContainer2>
    <div [ngClass]="{
        'swipe': true, 
        'resultados-principal': !sede, 
        'resultados-secundario': sede
    }">
        <table style="border-spacing: 0 10px !important; max-width: 1518px;" *ngIf=' carreras.length > 0'>
            <thead>
                <tr class="row-border" style="cursor: default !important;">
                    <th class="td-1 nunito-bold"></th>
                    <th class="td-2 nunito-bold">CARRERA</th>
                    <th class="td-3 nunito-bold">NIVEL</th>
                    <th class="td-4 nunito-bold">SEDE</th>
                    <th class="td-5 nunito-bold">FACULTAD</th>
                    <!-- <th>MODALIDAD</th> -->
                    <th class="td-6"> </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor='let carrera of carreras'>
                    <ng-container *ngIf="carrera.tag">
                        <tr>
                            <td [style.padding]="'0px'" colspan="2">
                                <span class="tag nunito-regular" [style.background]="carrera.color">
                                    {{carrera.tag}}
                                </span>
                            </td>
                        </tr>
                    </ng-container>

                    <tr id="resultado-buscador" class="table-row nunito-regular" (click)="verInfo(carrera)"
                        [ngClass]="{ 'fila-expandida': filaExpandida === carrera }">
                        <td [style.text-align]="'center'">
                            <img [src]="createLink(carrera.facultad?.icono)" alt="" class="icon">
                        </td>
                        <td class="nunito-regular"> {{carrera.title}} </td>
                        <td class="nunito-regular"> {{carrera.nivel}}</td>
                        <td class="nunito-regular">
                            <ng-container *ngIf="carrera.sede">
                                <!-- <ng-container *ngFor="let s of carrera.sede; let last = last">
                              {{ s.title}}
                              <ng-container *ngIf="!last">,</ng-container>
                            </ng-container> -->
                                {{carrera.sede}}
                            </ng-container>
                        </td>
                        <td class="nunito-regular">{{ eliminarPrefijoFacultad(carrera.facultad?.title) }}</td>
                        <!-- <td>
                        <ng-container *ngIf="carrera.modalidad == 'Presencial'">
                            <img src="../../../../../assets/img/presencial.png" alt="">
                        </ng-container>
                       
                        <ng-container *ngIf="carrera.modalidad == 'Online'">
                            <img src="../../../../../assets/img/online.png" alt="">
                        </ng-container>
                        <ng-container *ngIf="carrera.modalidad == 'Híbrida'" style="display: flex;">
                            <img src="../../../../../assets/img/presencial.png" alt="" >
                            <img src="../../../../../assets/img/online.png" alt="" >
                        </ng-container>
                    </td> -->
                        <td class="nunito-regular">
                            <!-- <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#003269" class="bi bi-chevron-down" Box="0 0 16 16" style="font-weight: 500;">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                        </svg> -->
                            <img *ngIf="filaExpandida != carrera" src="../../../../../assets/img/chevron-down-b.png"
                                alt="" class="flechita">
                            <img *ngIf="filaExpandida === carrera" src="../../../../../assets/img/chevro-up-b.png"
                                alt="" class="flechita">
                        </td>

                    </tr>
                    <tr
                        style="font-size: smaller !important; background-color: #ffffff9c ; cursor: default !important;">

                        <ng-container *ngIf="filaExpandida === carrera">
                            <td></td>
                            <td style="vertical-align: top;">
                                <p style="width: auto !important;" class="celeste  nunito-bold">Duración</p>

                                <p *ngIf="!carrera.duracion" style="width: auto !important; margin-bottom: 10px;"
                                    class="azul nunito-regular"> Proximamente toda la información </p>
                                <p *ngIf="carrera.duracion" style="width: auto !important; margin-bottom: 10px;"
                                    class="azul nunito-regular">
                                    {{carrera.duracion}}</p>

                                <p *ngIf="carrera.inicio" style="width: auto !important;" class="celeste  nunito-bold">
                                    Fecha de comienzo</p>
                                <!--<p *ngIf="!carrera.inicio" style="width: auto !important;" class="azul nunito-regular">-</p> 
                                -->
                                <p *ngIf="carrera.inicio" style="width: auto !important;" class="azul nunito-regular">{{
                                    carrera.inicio?.date | date:'dd/MM/yyyy'
                                    }}</p>
                            </td>
                            <td colspan="2" style="vertical-align: top;">
                                <p style="width: auto !important;" class="celeste  nunito-bold">Horarios</p>
                                <ng-container *ngIf="carrera.horarios">
                                    <ng-container *ngFor="let horario of carrera.horarios.split(',')">
                                        <p class="azul nunito-regular" style="margin-bottom: 0px; padding-bottom: 0px">
                                            {{
                                            horario.trim() }}
                                        </p>

                                    </ng-container>
                                </ng-container>
                                <p *ngIf="!carrera.horarios" style="width: auto !important;" class="azul">Proximamente
                                    toda la información</p>
                            </td>
                            <td class="" style="
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        height: 100%; margin-top: 10%;     font-weight: 100;">
                                <!-- VER redireccion -->
                                <a *ngIf="carrera.boton_inscribite" class="boton btn-amarillo nunito-regular"
                                    href="{{carrera.boton_inscribite}}">INSCRIBITE</a>

                                <a class="boton btn-azul nunito-regular" [href]="language +carrera.linkCareer"
                                    target="_blank">MÁS INFO</a>
                            </td>
                            <td></td>

                        </ng-container>

                    </tr>
                    <tr class="row-border"></tr>
                </ng-container>

            </tbody>
        </table>
        <div class="d-flex justify-content-center mt-3" *ngIf="spinner">
            <div class="spinner-grow spinner-b" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-b" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-b" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>


    </div>
    <!--   <div class="paginacion mt-3" *ngIf='carreras.length > 0 && pages.length > 1'>
        <div *ngFor="let page of pages; let i = index">
            <div *ngIf="i < 9  || numberActive < 9 || (i >= numberActive - 4 && i <= numberActive + 4)" class="number"
                [ngClass]='(numberActive == page) ? "active" : ""'
                (click)='cambiarPagina(page, false, true)'>
                {{ page }}
            </div>
        </div>
        <div class="number" *ngIf="pages.length > 10 && numberActive < pages.length - 4">
            ...
        </div>
        <div class="number"
                *ngIf="pages.length > 10 && (numberActive >= 9 || numberActive >= pages.length - 4)"
                [ngClass]='(numberActive == pages.length) ? "active" : ""'
                (click)='cambiarPagina(pages.length, false, true)'>
            {{ pages.length }}
        </div>
   
       
          
      </div> -->
    <div class="paginacion mt-3" *ngIf='carreras.length > 0 && pages.length > 1'>
        <ng-container *ngFor="let page of visiblePages2">
            <div class="number" [ngClass]='(numberActive == page) ? "active" : ""' *ngIf="page !== '...'"
                (click)='cambiarPagina(page, false, true)'>
                {{ page }}
            </div>
            <div class="dots" *ngIf="page === '...'">
                {{ page }}
            </div>
        </ng-container>
    </div>







    <div *ngIf=' carreras.length == 0 && !destacadas  && !spinner' class='mt-3 p-3 text-center'>
        <div class="alert alert-primary text-center" role="alert">
            No hay resultados
        </div>
    </div>
</div>